.LobbyGame {
  cursor: pointer;
  padding: 1rem;
  line-height: 1rem;

  &:hover {
    background: #1e1e1e;
  }

  &--title {
    display: flex;
    line-height: 2rem;
  }

  &--players {
    margin-left: 1rem;
    color: #3b89ff;
  }

  &--ready {
    .LobbyGame--players {
      color: red;
    }
  }

  &--startedBy, &--gameId {
    font-size: 0.5rem;
    opacity: 0.2;
    letter-spacing: 1px;
  }
}

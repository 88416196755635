.Square {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000;
  padding: 10px;
  font-size: 0.8rem;
  position: relative;

  .stamp {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 120%;
    z-index: 5;
    transition: all 0.2s linear;
    opacity: 0;
    transform: scale(1.5);
    pointer-events: none;

    &.cheese {
      background-image: url("/cheese.png");
    }

    &.badge {
      background-image: url("/badge2.png");
    }
  }

  .answer {
    transition: all 0.1s linear;
  }

  &.checked {
    .answer {
      opacity: 0.2;
    }

    .cheese {
      opacity: 1;
      transform: scale(1);
    }

    .badge {
      opacity: 1;
      transform: scale(1);
    }
  }

  img {
    height: 46px;
    width: 46px;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #2e2e2e;
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  background: none;
}

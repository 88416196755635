@import "../../styles/mixins";

.LobbyRoute {
  display: grid;
  grid-template-areas: 'route-title route-title' 'user user' 'create-game join-game';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto 1fr;
  flex: 1;

  @include media-query(min){
    grid-template-areas: 'route-title' 'user' 'create-game' 'join-game';
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr 1fr;
  }

  .RouteTitle {
    grid-area: route-title;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    line-height: 4rem;
    padding-top: 5rem;
  }

  .User {
    grid-area: user;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    color: #00adff;
  }

  .CreateGame {
    grid-area: create-game;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .JoinGame {
    grid-area: join-game;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Title {
    padding: 10px 15px;
    cursor: pointer;

    &:hover {
      background: #3a3a3a;
    }
  }
}

.ServerStatus {
  font-size: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  margin-top: 15px;

  &--icon {
    border-radius: 50px;
    background: red;
    width: 8px;
    height: 8px;
    margin-right: 8px;
  }

  &.Connecting {
    .ServerStatus--icon {
      background: #22bed6;
    }
  }

  &.Connected {
    .ServerStatus--icon {
      background: #00d600;
    }
  }
}
